<template>
    <b-container fluid>
        <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">
                {{ $t('elearning_tim.honorarium') }} {{ $t('globalTrans.search') }}
              </h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="fiscal_year_id"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                          :filter-by="myFilter"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1" >
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="org_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.organization')}}
                        </template>
                        <!-- <b-form-select
                          plain
                          v-model="search.org_id"
                          :options="orgList"
                          id="org_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select> -->
                        <v-select name="org_id"
                          v-model="search.org_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= orgList
                          :placeholder="$t('globalTrans.select')"
                          :filter-by="myFilter"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <!-- <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select> -->
                        <v-select name="training_type_id"
                          v-model="search.training_type_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= trainingTypeList
                          :placeholder="$t('globalTrans.select')"
                          :filter-by="myFilter"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <!-- <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select> -->
                        <v-select name="training_category_id"
                          v-model="search.training_category_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= trainingCategoryList
                          :placeholder="$t('globalTrans.select')"
                          :filter-by="myFilter"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <!-- <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select> -->
                        <v-select name="training_title_id"
                          v-model="search.training_title_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= trainingTitleList
                          :placeholder="$t('globalTrans.select')"
                          :filter-by="myFilter"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="batch_no"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.batch_no')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.batch_no"
                            :options="batchList"
                            id="batch_no"
                            >
                            <template v-slot:first>
                                <b-form-select-option value=0>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="circular_memo_no"
                        >
                          <template v-slot:label>
                            {{$t('elearning_iabm.circular_memo_no')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.circular_memo_no"
                            :options="circularList"
                            id="circular_memo_no"
                            >
                            <template v-slot:first>
                                <b-form-select-option value=''>{{ circularLoading ? 'Loading....' : $t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('elearning_tim.honorarium') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <router-link to="bill-payment-form" :class="'btn btn_add_new font-weight-bold'"><i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}</router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                          <b-row>
                            <b-col md="12" class="table-responsive">
                                <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                    <template v-slot:cell(index)="data">
                                    {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(type_name)="data">
                                      <span class="capitalize">{{ data.item.type_name }}</span>
                                    </template>
                                    <template v-slot:cell(personal_info_id)="data">
                                      {{ ($i18n.locale==='bn') ? data.item.personal.name_bn : data.item.personal.name }}
                                    </template>
                                    <template v-slot:cell(details)="data">
                                      <router-link :to="`bill-payment-all-details?circilarMemoNo=${data.item.circular_memo_no}&batch_no=${data.item.batch_no}`" title="View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                    </template>
                                    <template v-slot:cell(attachment)="data">
                                        <a target = '_blank' v-if="data.item.honorarium_attachment" :href="trainingElearningServiceBaseUrl + 'storage/uploads/attachment/' + data.item.honorarium_attachment" download><span class="badge badge-success">({{ $t('globalTrans.view_download') }})</span></a>
                                        <!-- <b-img width='100px' :src="trainingElearningServiceBaseUrl+'storage/'+data.item.attachment" fluid alt="Right Logo"></b-img> -->
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <a href="javascript:" :class="'btn_table_action table_action_status'" v-b-modal.modal-4 title="Upload Attachment" @click="edit(data.item)"><i class="fas fa-file-alt"></i>
                                          <!-- {{$t('globalTrans.attachment')}}  -->
                                        </a>
                                        <router-link :to="`bill-payment-circular-wise?circilarMemoNo=${data.item.circular_memo_no}`" title="View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link>
                                        <!-- <router-link :to="`bill-payment-details?circilarMemoNo=${data.item.circular_memo_no}`" title="View" :class="'btn_table_action table_action_view'"><i class="fas fa-eye"></i></router-link> -->
                                    </template>
                                </b-table>
                                <b-pagination
                                    align="center"
                                    v-model="pagination.currentPage"
                                    :per-page="pagination.perPage"
                                    :total-rows="pagination.totalRows"
                                    @input="searchData"
                                    />
                            </b-col>
                          </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="$t('globalTrans.honour_amount')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Attachment :id="editItemId" :key="editItemId"/>
        </b-modal>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentList, billGeneratePaymentUpdate, circularList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Attachment from './Attachment'
// import Payment from './Payment'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Attachment
    //   Details
    },
    data () {
        return {
          search: {
            circular_memo_no: '',
            coordinator_id: 0,
            org_id: null,
            batch_no: 0,
            training_type_id: null,
            training_category_id: null,
            training_title_id: null,
            training_status: 1,
            payment_type: 'Trainer',
            fiscal_year_id: null
          },
          circularLoading: false,
          allBatchListData: [],
          trainingTypeList: [],
          batchList: [],
          circularList: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          myFilter: (option, text, search) => {
                    const temp = search.toLowerCase()
                    return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                    option.text_bn.toLowerCase().indexOf(temp) > -1
                }
        }
    },
    computed: {
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_tim.bill_payment_entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('elearning_config.fiscal_year'), class: 'text-center' },
              { label: this.$t('elearning_config.training_title'), class: 'text-center' },
              { label: this.$t('elearning_iabm.circular_memo_no'), class: 'text-center' },
              { label: this.$t('elearning_iabm.batch_no'), class: 'text-center' },
              { label: this.$t('globalTrans.attachment'), class: 'text-center' },
              { label: this.$t('elearning_tim.tax_certificate'), class: 'text-center' },
              { label: this.$t('elearning_tim.honorarium'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'batch_name_bn' },
              { key: 'attachment' },
              { key: 'details' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'training_title' },
              { key: 'circular_memo_no' },
              { key: 'batch_name' },
              { key: 'attachment' },
              { key: 'details' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      },
      allBatchList () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
          { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
          { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
          { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
          { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' },
          { value: 6, text: this.$i18n.locale === 'en' ? '6th' : '৬ষ্ঠ', text_en: '6th', text_bn: '৬ষ্ঠ' },
          { value: 7, text: this.$i18n.locale === 'en' ? '7th' : '৭ম', text_en: '7th', text_bn: '৭ম' },
          { value: 8, text: this.$i18n.locale === 'en' ? '8th' : '৮ম', text_en: '8th', text_bn: '৮য়' },
          { value: 9, text: this.$i18n.locale === 'en' ? '9th' : '৯ম', text_en: '9th', text_bn: '৯ম' },
          { value: 10, text: this.$i18n.locale === 'en' ? '10th' : '১০ম', text_en: '10th', text_bn: '১০ম' }
        ]
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            // org_id: this.$store.state.dataFilters.orgId,
            fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.search.org_id = this.$store.state.Auth.authUser.org_id
          if (this.$store.state.Auth.authUser.is_org_admin !== 1 && parseInt(this.$store.state.Auth.authUser.org_id) === 12) {
            this.search.coordinator_id = this.$store.state.Auth.authUser.user_id
          }
        }
        this.paymentStatusUpdate()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.org_id': function (newValue) {
        this.trainingTypeList = this.getTypeList(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.training_title_id': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getCircularList()
        }
      },
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.circular_memo_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => String(allBatchListData.circular_memo_no) === String(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.batch_no = allBatchListData.batch_no
          }
        }
      },
      'search.batch_no': function (newVal, oldVal) {
        if (newVal !== oldVal) {
          const allBatchListData = this.allBatchListData.find(allBatchListData => parseInt(allBatchListData.batch_no) === parseInt(newVal))
          if (typeof allBatchListData !== 'undefined') {
            this.search.circular_memo_no = allBatchListData.circular_memo_no
          }
        }
      }
    },
    methods: {
      getTypeList (orgId) {
        const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
        if (orgId) {
          return type.filter(item => item.org_id === parseInt(orgId))
        }
        return type
      },
      batchInfo () {
        const batchData = this.allBatchListData.filter(allBatchList => parseInt(allBatchList.training_title_id) === parseInt(this.search.training_title_id))
        const batchList = []
        batchData.map((obj, index) => {
          if (obj.batch_no) {
            const isThere = this.allBatchList.find(allBatchList => parseInt(allBatchList.value) === parseInt(obj.batch_no))
            if (typeof isThere !== 'undefined') {
              batchList.push(isThere)
            }
          }
        })
        this.batchList = batchList
        if (this.batchList.length === 1) {
          this.search.batch_no = 1
        }
      },
      circluarList () {
        const listObject = this.allBatchListData.filter(allBatchListData => parseInt(allBatchListData.training_title_id) === parseInt(this.search.training_title_id))
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no, batch_no: obj.batch_no }
          }
        })
        this.circularList = tmpList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          fiscal_year_id: this.search.fiscal_year_id,
          training_type_id: this.search.training_type_id,
          training_category_id: this.search.training_category_id,
          training_title_id: this.search.training_title_id,
          coordinator_id: this.search.coordinator_id
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularList + '/' + 'iab_circular_publications', serchData)
        if (!result.success) {
        } else {
          this.allBatchListData = result.batchList
          this.batchInfo()
          this.circluarList()
        }
        this.circularLoading = false
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const divisionList = this.$store.state.commonObj.divisionList
        const districtList = this.$store.state.commonObj.districtList
        const upazilaList = this.$store.state.commonObj.upazilaList
        const unionList = this.$store.state.commonObj.unionList
        const listData = data.map(item => {
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
          const trainingCategoryData = {}
          if (typeof trainingCategoryObj !== 'undefined') {
            trainingCategoryData.training_category = trainingCategoryObj.text_en
            trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
          } else {
            trainingCategoryData.training_category = ''
            trainingCategoryData.training_category_bn = ''
          }
          const desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          const desigData = {}
          if (typeof desigObj !== 'undefined') {
            desigData.designation_name = desigObj.text_en
            desigData.designation_name_bn = desigObj.text_bn
          } else {
            desigData.designation_name = ''
            desigData.designation_name_bn = ''
          }
          const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch_name = batchObj.text_en
            batchData.batch_name_bn = batchObj.text_bn
          } else {
            batchData.batch_name = ''
            batchData.batch_name_bn = ''
          }
          const divisionObject = divisionList.find(division => division.value === item.pre_division_id)
          const districtObject = districtList.find(district => district.value === item.pre_district_id)
          const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.pre_upazilla_id)
          const unionObject = unionList.find(union => union.value === item.pre_union_id)
          const divisionData = {
              division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
              division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
          }
          const districtData = {
            district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
            district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
          }
          const upazillaData = {
            upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
            upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
          }
          const unionData = {
            union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
            union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
          }
          const address = divisionData.division_name + ',' + districtData.district_name + ',' + upazillaData.upazilla_name + ',' + unionData.union_name
          const addressData = {
            address: address
          }
          return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, batchData, addressData)
        })
        return listData
      },
      async paymentStatusUpdate () {
        if (this.$route.params.status && this.$route.query.trnsId) {
          const status = this.$route.params.status
          const trnsId = this.$route.query.trnsId
          await RestApi.getData(trainingElearningServiceBaseUrl, billGeneratePaymentUpdate + status, { trnsId: trnsId }).then(response => {
            if (response.success) {
              this.$toast.success({
                title: 'Success',
                message: response.message,
                color: '#D6E09B'
              })
            } else {
              this.$toast.error({
                title: 'Error',
                message: response.message
              })
            }
            this.loadData()
            this.$router.push('/training-e-learning-service/tim/bill-payment')
          })
        } else {
          this.loadData()
        }
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>
